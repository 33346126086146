<template>
  <div>
    <div class="title_div">我的</div>
      <!--主体-->
      <div>
        <div style="display:flex;justify-content:center;align-items:center;height:16vh;border-bottom: 1px solid #E2E2E2;">
          <div>
            <img src="@/assets/image/moren.png" style="width:15vw;height:15vw;border-radius:50%;">
          </div>
          <div style="font-size:3.4vw;margin-left:8vw;">
            <div>门店:{{user_info.shopName=='%%'?'总店':user_info.shopName}}</div>
            <div style="margin:1vh 0;">我的姓名:{{user_info.username}}</div>
            <div>我的手机:{{user_info.mobile}}</div>
          
          </div>
        </div>
        <div class="every_option" @click="changePasswordBtn()">
          <div style="display:flex;">
            <img src="@/assets/icon/xiugaimima.png" style="height:4vw;width:4vw;margin-left:7vw;">
            <div style="font-size:3.4vw;margin-left:6vw;" >修改密码</div>
          </div>
          <div >
            <img src="@/assets/icon/fanhui.png" style="height:4vw;width:4vw;margin-right:4vw;">
          </div>
        </div>
        <div class="every_option" @click="loginPopup()">
          <div style="display:flex;">
            <img src="@/assets/icon/tuichu.png" style="height:4vw;width:4vw;margin-left:7vw;">
            <div style="font-size:3.4vw;margin-left:6vw;" >退出登录</div>
          </div>
          <div >
            <img src="@/assets/icon/fanhui.png" style="height:4vw;width:4vw;margin-right:4vw;">
          </div>
        </div>
      </div>
    <!--底部tab栏-->
    <Tabbar :choose_index="tab_type" :user_type2="user_type2" :type="pagetype"></Tabbar>
    <!--修改密码弹窗-->
    <van-popup v-model="popup" round>
      <div v-if="popup_type==1" style="width:70vw;">
        <div style="text-align:center;margin:16px 0;font-size:18px;">修改密码</div>
        <div style="display:flex;justify-content:center;margin:20px 0;">
          <div style="font-size:16px;margin-right:10px;">原密码</div>
          <input type="password" v-model="password" class="search_input" placeholder="请输入">
        </div>
        <div  style="display:flex;justify-content:center;">
          <div style="font-size:16px;margin-right:10px;" >新密码</div>
          <input type="password" v-model="newPassword" class="search_input" placeholder="请输入">
        </div>
        <div style="display:flex;justify-content:center;margin:20px 0;">
          <van-button size="small" @click="popup=false">取消修改</van-button>
          <div style="width:20px;"></div>
          <van-button type="primary" size="small" @click="update_api()">确定修改</van-button>
        </div>
      </div>
      <!---->
      <div v-if="popup_type==2" style="width:60vw;">
        <div style="text-align:center;margin:30px 0;font-size:16px;">确定退出登录?</div>
          <div style="display:flex;justify-content:center;margin:20px 0;">
          <van-button size="small" @click="popup=false">取消退出</van-button>
          <div style="width:20px;"></div>
          <van-button type="primary" size="small" @click="goLogin()">确定退出</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Tabbar from "@/components/Tabbar";
import wx from "weixin-js-sdk";
// import exportMethod from "../exportMethod"
export default {
  components: {
    Tabbar,
  },
  data() {
    return {
      tab_type:0,
      user_info:{},
      popup:false,//修改密码
      popup_type:1,//弹窗类型 1 修改密码 2退出登录
      password:'',//原密码
      newPassword:'',//新密码
      loading:'',//loading
      user_type2:false,//是否是区域看板
      pagetype:0,//1总部看板
    };
  },
  created(){
    this.tab_type = this.$route.query.pagetype * 1;
    console.log("aaaaaaaaa")
    // this.user_api()
   if(localStorage.getItem("user_info")!=""){
    
    if(JSON.parse(localStorage.getItem("user_info")).type==2){
      this.user_type2 = true;
    }
    this.user_info = JSON.parse(localStorage.getItem("user_info"));
    if(JSON.parse(localStorage.getItem("user_info")).type==0){
      this.pagetype = 1;
    }
   }
   
  },
  methods: {

    // user_api(){
    //   // this.showLoading();
    //   this.$axios.post(this.$api.user_api).then(res=>{
    //    console.log(res,"用户信息")
    //    localStorage.setItem("user_info", JSON.stringify(res.data.data));
     
    //   })
    // },
    //loading
    showLoading(){
       this.loading = this.$loading({
          lock: true,
          text: '查询中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
    },
    //退出登录提示
    loginPopup(){
      this.popup_type = 2;
      this.popup = true;
    },
    //退出登录
    goLogin(){
      localStorage.setItem("user_info",'');
      localStorage.setItem("token",'');
      this.$router.push('./login');
      localStorage.removeItem('quanxian');
      // window.location.replace("https://babb.boaiyiyao.com/api/#/login")
      // wx.miniProgram.navigateTo({
      //   url: `/pages/index/index?type=111`,
      // });
    },
    //修改密码弹窗
    changePasswordBtn(){
      this.popup_type = 1;
      this.popup = true;
    },
    //修改成功
    update_api(){
        this.showLoading();
        this.$axios.post(this.$api.update_api,{
          mobile:this.user_info.mobile,
          password:this.password,
          newPassword:this.newPassword,
        }).then(res=>{
          this.loading.close();
          if(res.data.code==0){
            this.$message({
              message:'修改成功',
              type: 'success'
            });
            this.password = '';
            this.newPassword = '';
            this.popup = false;
          }else{
            this.$message({
              message:res.data.msg,
              type: 'warning'
            });
          }
        })
    }
  },
};
</script>
<style scoped>
.title_div{
  height:9vh;
  background-image: linear-gradient(#04A9F3, #4EC5FB);
  line-height:12vh;
  color:#FFFFFF;
  text-align: center;
  font-size:4.6vw;
}
.every_option{
  height:7vh;
  display:flex;
  justify-content:space-between;
  align-items:center;
  border-bottom: 1px solid #E2E2E2;
}
.search_input{

  outline: none;
  border: none;
  width:40vw;
  border-bottom:1px solid #e7e6e6;
  font-size: 14px;
  padding-left:10px;
}
</style>